<template>
  <content-renderer
    v-if="page"
    :value="page"
  />
</template>

<script setup lang="ts">
const route = useRoute()
const { data: page } = await useAsyncData(`content:${route.path}`, () => queryCollection('content').path(`/pages${route.path}`).first())

const pageSEO = computed(() => ({
  title: page.value?.title,
  description: page.value?.description,
}))

useSeoMeta({
  title: pageSEO.value.title,
  description: pageSEO.value.description,
  ogTitle: pageSEO.value.title,
  ogDescription: pageSEO.value.description,
})

useHead({
  title: page.value?.title,
})
</script>
